/**
 * Module definition and dependencies
 */
angular.module("Crm.Annexe.Controller", ["documentsService.Service", "configsService.Service", "lotsService.Service"])
/**
 * Controller
 */
.controller("annexeCtrl", ["DocumentsLot", "Lot", "LotDetails", "ConfigsCommercialisateurs", function (DocumentsLot, Lot, LotDetails, ConfigsCommercialisateurs) {
  const categorieIdPlanDeVente = 1;
  this.tabAnnexe = [];

  this.$onInit = function () {
    this.deviceUnit = this.transaction.device_unit;
    this.buildTabAnnexe();
    DocumentsLot.get({
      lotId: this.lot.id
    }).$promise.then(response => {
      this.documentsLot = response.data;
      this.findPlanDeVente();
    }).catch(error => {
      console.error("error :", error);
    });
    ConfigsCommercialisateurs.getConfigCommercialisateurs().then(response => {
      this.configsCommercialisateurs = response.data; // is_enabled_lots_annexes === 0 on utilise les lots liés

      if (!this.configsCommercialisateurs.is_enabled_lots_annexes) {
        this.getLotDetails();
      }
    }).catch(error => {
      console.log(error);
    });
  };

  this.getLotDetails = () => {
    LotDetails.get({
      lotId: this.lot.id
    }).$promise.then(response => {
      this.lotDetail = response;

      if (this.lotDetail["Stationnements et caves"]) {
        this.buildTabAnnexe(this.lotDetail["Stationnements et caves"]);
      }
    }).catch(error => {
      console.log("error :", error);
    });
  };

  this.buildTabAnnexe = (annexes = false) => {
    const lotIsFind = this.tabAnnexe.find(a => a.nom === this.lot.nom && a.type === this.lot.type); // Ajout du lot/transaction

    if (!lotIsFind) {
      this.tabAnnexe.push({
        nom: this.lot.nom,
        type: this.lot.type,
        etage: this.lot.etage,
        surface: this.lot.surface,
        prix_ht: this.transaction.prix_ht,
        prix_ttc: this.transaction.prix_ttc
      });
    } // Ajout des lots annexes


    if (this.transaction.lots_annexes.length) {
      this.transaction.lots_annexes.forEach(annexeId => {
        this.getLot(annexeId).then(lot => {
          this.tabAnnexe.push({
            nom: lot.nom,
            type: lot.nature,
            etage: lot.etage,
            surface: lot.surface,
            prix_ht: ""
          });
        });
      });
    } // Ajout des lots liés


    if (annexes) {
      for (let i in annexes) {
        this.tabAnnexe.push({
          nom: annexes[i],
          type: i,
          etage: "",
          surface: "",
          prix_ht: ""
        });
      }
    }
  };

  this.getLot = lotId => {
    return Lot.get({
      lotId: lotId
    }).$promise.then(response => {
      return response;
    }).catch(error => {
      console.log("getLot error :", error);
    });
  };

  this.findPlanDeVente = () => {
    if (!this.documentsLot) {
      return;
    }

    this.planDeVente = this.documentsLot.find(d => d.categorie_id === categorieIdPlanDeVente);
  };

  this.download = url => {
    const link = document.createElement("a");
    link.download = name;
    link.href = url;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
}]);
/**
 * Module definition and dependencies
 */

angular.module("Crm.Annexe", ["Crm.Annexe.Controller", "Crm.annexe.templates"])
/**
 * Config
 */
.component("annexe", {
  controller: "annexeCtrl",
  templateUrl: "src/annexe/annexe.html",
  bindings: {
    lot: "<",
    transaction: "=",
    programme: "<"
  }
});
angular.module('Crm.annexe.templates', []).run(['$templateCache', function($templateCache) {$templateCache.put('src/annexe/annexe.html','<div class="panel panel-default contact"><div class="panel-heading"><h3 class="panel-title">Informations lot et annexes</h3></div><div class="panel-body"><div class="row"><div class="col-xs-12 col-lg-6 mb-1"><p>Programme {{ $ctrl.programme.nom }}</p><p> <span ng-if="$ctrl.programme.adresse1 || $ctrl.programme.adresse2">{{ $ctrl.programme.adresse1 }} {{ $ctrl.programme.adresse2 }},</span> {{ $ctrl.programme.code_postal }} {{ $ctrl.programme.ville }}</p></div><div class="col-xs-12 col-lg-6 text-center"> <button class="btn btn-primary mb-1" ng-click="$ctrl.download($ctrl.planDeVente.url)" ng-if="$ctrl.planDeVente"> T\xE9l\xE9charger le plan de vente du lot</button></div></div><div class="table-responsive"><table class="table table-striped table-bordered"><thead class="text-center"><tr><th>N\xB0 lot</th><th>Type</th><th>\xC9tage</th><th>Surface</th><th>Prix HT</th></tr></thead><tbody><tr ng-repeat="elem in $ctrl.tabAnnexe track by $index"><td>{{ elem.nom }}</td><td>{{ elem.type }}</td><td ng-class="{\'text-center\': elem.etage.length == 0}"> <span ng-if="elem.etage.length > 0">{{ elem.etage }}</span> <span ng-if="elem.etage.length == 0">-</span></td><td ng-class="{\'text-center\': elem.surface.length == 0}"> <span ng-if="elem.surface">{{ elem.surface }} m<sup>2</sup></span></td><td ng-class="{\'text-center\': elem.prix_ht.length == 0, \'text-right\':\r\n            elem.prix_ht.length > 0}"> <span ng-if="elem.prix_ht">{{ elem.prix_ht | currency: $ctrl.deviseUnite :0 }}</span> <span ng-if="elem.prix_ht.length == 0">-</span></td></tr></tbody></table></div></div></div>');}]);