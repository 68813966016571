/**
 * Module definition and dependencies
 */
angular.module('Crm.MesContactsLibFront.Controller', [])
/**
 * Controller
 */
.controller('listContactsCtrl', [function () {
  let self = this;

  self.$onInit = function () {};

  self.removeContact = function (item) {
    let index = self.listContacts.indexOf(item);

    if (index !== -1) {
      self.listContacts.splice(index, 1);
    }
  };
}]);
/**
 * Module definition and dependencies
 */

angular.module('Crm.MesContactsLibFront', ['Crm.MesContactsLibFront.Controller', 'Crm.mes-contacts.templates'])
/**
 * Route component
 */
.component('listContacts', {
  controller: 'listContactsCtrl',
  templateUrl: 'src/mes-contacts/list-contacts-well.html',
  bindings: {
    listContacts: '='
  }
});
angular.module('Crm.mes-contacts.templates', []).run(['$templateCache', function($templateCache) {$templateCache.put('src/mes-contacts/list-contacts-well.html','<div class="mt-1" ng-if="$ctrl.listContacts.length > 0"> <span class="well well-sm" ng-repeat="item in $ctrl.listContacts" ng-click="$ctrl.removeContact(item)">{{ item.nom }} {{ item.prenom }}<span ng-if="item.email"><{{ item.email }}></span> <i class="material-icons md-18">close</i></span></span></div><p ng-if="$ctrl.listContacts.length === 0">Aucun destinataire</p>');}]);