/**
 * Module definition and dependencies
 */
angular.module("Crm.LotTransaction.Controller", ["transactionsService.Service", "lotsService.Service", "ngMessages"])
/**
 * Controller
 */
.controller("LotTransactionCtrl", [function () {
  var self = this;

  self.$onInit = function () {
    this.tabAnnexe = [];
    this.totalAnnexe = this.transaction.total_lots_annexes ? this.transaction.total_lots_annexes : 0; // Check si remise

    this.isRemise = this.transaction && this.transaction.prix_ttc && this.transaction.prix && this.transaction.prix_ttc != this.transaction.prix;
  };

  this.getTotalTransaction = () => {
    return parseFloat(this.transaction.prix_ttc) + this.totalAnnexe;
  };
}]);
/**
 * Module definition and dependencies
 */

angular.module("Crm.LotTransaction", ["Crm.LotTransaction.Controller", "Crm.lot-transaction.templates"])
/**
 * component
 */
.component("lotTransaction", {
  controller: "LotTransactionCtrl",
  templateUrl: "src/lot-transaction/lot-transaction.html",
  bindings: {
    lot: "<",
    transaction: "<"
  }
});
angular.module('Crm.lot-transaction.templates', []).run(['$templateCache', function($templateCache) {$templateCache.put('src/lot-transaction/lot-transaction.html','<div class="panel panel-default vendeur"><div class="panel-heading"><h3 class="panel-title">Prix</h3></div><div class="panel-body"><table class="table table-striped table-bordered"><thead><tr><th>Nom</th><th>Prix</th></tr></thead><tbody><tr><td>Prix HT du lot seul</td><td class="text-right"> {{ $ctrl.transaction.prix_ht | currency: $ctrl.transaction.devise_unite :0 }}</td></tr><tr><td>Montant de la TVA</td><td class="text-right">{{ $ctrl.transaction.tva }} %</td class="text-right"></tr><tr><td>Prix TTC du lot</td><td class="text-right"> {{ $ctrl.transaction.prix_ttc | currency: $ctrl.transaction.devise_unite :0 }}</td></tr><tr ng-repeat="annexe in $ctrl.tabAnnexe"><td>Annexe {{ annexe.nom }}</td><td class="text-right"> {{ annexe.prix_ttc | currency: $ctrl.transaction.devise_unite :0 }}</td></tr><tr><td>Total des annexes</td><td class="text-right"> {{ $ctrl.totalAnnexe | currency: $ctrl.transaction.devise_unite :0}}</td></tr><tr><td>Total TTC de la transaction avant remise</td><td class="text-right"> {{ $ctrl.getTotalTransaction() | currency: $ctrl.transaction.devise_unite :0 }}</td></tr><tr ng-if="$ctrl.isRemise"><td>Total TTC de la transaction apr\xE8s remise</td><td class="text-right">{{ $ctrl.transaction.prix | currency: $ctrl.transaction.devise_unite :0 }}</td></tr></tbody></table></div></div>');
$templateCache.put('src/lot-transaction/modal-transaction.html','<div class="modal-header"><h3 ng-switch="$ctrl.typeAction" class="modal-title h4" id="modal-title"> <span ng-switch-when="abandonner"><span ng-switch="$ctrl.item.type_label"><span ng-switch-when="R\xE9serv\xE9">Voulez-vous vraiment abandonner cette r\xE9servation ?</span> <span ng-switch-when="Optionn\xE9">Voulez-vous vraiment abandonner cette option ?</span> <span ng-switch-when="Vendu">Voulez-vous vraiment abandonner cette vente ?</span> <span ng-switch-when="Sign\xE9">Voulez-vous vraiment abandonner cette transaction en cours de signature ?</span></span></span> <span ng-switch-when="valider"><span ng-switch="$ctrl.item.type_label"><span ng-switch-when="R\xE9serv\xE9">Voulez-vous vraiment valider cette r\xE9servation ?</span> <span ng-switch-when="Optionn\xE9">Voulez-vous vraiment valider cette option ?</span> <span ng-switch-when="Vendu">Voulez-vous vraiment valider cette vente ?</span> <span ng-switch-when="Sign\xE9">Voulez-vous vraiment valider cette transaction en cours de signature ?</span></span></span></h3></div><div class="modal-body" id="modal-body"> Transaction du lot <strong>{{ $ctrl.item.lot_nom }}</strong> du programme <strong>{{ $ctrl.item.programme_nom }}</strong> par le client <strong>{{ $ctrl.item.client_nom + \' \' + $ctrl.item.client_prenom }}</strong><textarea ng-model="$ctrl.item.note" class="form-control mt-1" rows="5" placeholder="Message"></textarea></div><div class="modal-footer"> <button class="btn btn-danger" type="button" ng-click="$ctrl.annuler()">Annuler</button> <button class="btn btn-primary" type="button" ng-click="$ctrl.valider($ctrl.item.id)" promise-btn><span class="btn-text">Valider</span></button></div>');}]);