/* ===========================================================
 * fr.js
 * French translation for Trumbowyg
 * http://alex-d.github.com/Trumbowyg
 * ===========================================================
 * Author : Alexandre Demode (Alex-D)
 *          Twitter : @AlexandreDemode
 *          Website : alex-d.fr
 * Reviewed by : Abdou Developer (test20091)
 * 	    Github : https://github.com/test20091
 */
jQuery.trumbowyg.langs.fr={viewHTML:"Voir le HTML",undo:"Annuler",redo:"Refaire",formatting:"Format",p:"Paragraphe",blockquote:"Citation",code:"Code",header:"Titre",bold:"Gras",italic:"Italique",strikethrough:"Barré",underline:"Souligné",strong:"Fort",em:"Emphase",del:"Supprimé",superscript:"Exposant",subscript:"Indice",unorderedList:"Liste à puces",orderedList:"Liste ordonnée",insertImage:"Insérer une image",insertVideo:"Insérer une video",link:"Lien",createLink:"Insérer un lien",unlink:"Supprimer le lien",justifyLeft:"Aligner à gauche",justifyCenter:"Centrer",justifyRight:"Aligner à droite",justifyFull:"Justifier",horizontalRule:"Insérer un séparateur horizontal",removeformat:"Supprimer formatage",fullscreen:"Plein écran",close:"Fermer",submit:"Valider",reset:"Annuler",required:"Obligatoire",description:"Description",title:"Titre",text:"Texte",target:"Cible"};