/**
 * Module definition and dependencies
 */
angular.module('membresService.Service', ['ngResource'])
/**
 * Service
 */
.factory('Membre', ['$resource', 'Config', function ($resource, Config) {
  var membreApi = $resource(Config.API_BASE_URL + '/membres/:membreId', {
    membreId: '@membreId'
  }, {
    update: {
      method: 'PUT'
    },
    query: {
      isArray: false
    }
  });
  return membreApi;
}]).factory('MembreDocuments', ['$resource', 'Config', function ($resource, Config) {
  var documentsApi = $resource(Config.API_BASE_URL + '/documents', {}, {
    query: {
      isArray: false
    }
  });
  return documentsApi;
}]).factory('DocumentsCategories', ['$resource', 'Config', function ($resource, Config) {
  var documentsCategoriesApi = $resource(Config.API_BASE_URL + '/documents/categories', {}, {});
  return documentsCategoriesApi;
}]);