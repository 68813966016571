angular.module("Crm.EspaceClient.Controller", []).controller("EspaceClientCtrl", ["$rootScope", "$uibModal", "ConfigsCommercialisateurs", "ConfigsContacts", "TransactionsClient", function ($rootScope, $uibModal, ConfigsCommercialisateurs, ConfigsContacts, TransactionsClient) {
  this.$onInit = () => {
    this.btnActiverEspaceClient = false;
    this.userspace_url;
    ConfigsCommercialisateurs.getConfigCommercialisateurs().then(response => {
      $rootScope.use_userspace = response.data && response.data.use_userspace ? response.data.use_userspace : false;
      this.userspace_url = response.data.userspace_url;

      if (this.userspace_url) {
        this.getTransactionsClient();
      }
    }).catch(error => {
      console.log(error);
    });
  };

  this.getUrlEspaceClient = () => {
    ConfigsContacts.getToken(this.client.id, {
      seachWithRepository: true
    }).then(response => {
      if (response.data && response.data.token) {
        this.espaceClientURL = this.userspace_url + "?token=" + response.data.token;
      }
    }).catch(error => {
      console.log(error);
    });
  };

  this.getTransactionsClient = () => {
    TransactionsClient.query({
      clientId: this.client.id,
      etat: 4,
      limit: -1,
      signature_procedure_status: "null,-20,0,10,20,30,35,40,45,50,52,60,65"
    }).$promise.then(response => {
      if (!response || !response.data) {
        return;
      }

      angular.forEach(response.data, (value, key) => {
        if (value.type_id >= 2) {
          this.btnActiverEspaceClient = true;
        }
      });

      if (this.btnActiverEspaceClient) {
        this.getUrlEspaceClient();
      }
    }).catch(error => {
      console.log(error);
    });
  };

  this.openModalEspaceClient = () => {
    const modalInstance = $uibModal.open({
      templateUrl: "src/espace-client/modal-confirmation-espace-client.html",
      controller: "ConfirmerEspaceClientModalCtrl",
      controllerAs: "$ctrl",
      resolve: {
        contact: () => {
          return this.client;
        },
        transaction: () => {
          return this.transaction;
        }
      }
    });
    modalInstance.result.then(selectedItem => {
      self.selected = selectedItem;
    });
  };
}]).controller("ConfirmerEspaceClientModalCtrl", ["$uibModalInstance", "contact", "transaction", "$state", "ContactActiverEspaceClient", "toaster", "$rootScope", "$scope", function ($uibModalInstance, contact, transaction, $state, ContactActiverEspaceClient, toaster, $rootScope, $scope) {
  this.$onInit = () => {
    $scope.contact = contact;
    $scope.transaction = transaction;
  };

  this.valider = () => {
    const contactActiverEspaceClient = new ContactActiverEspaceClient({
      contact_id: contact.id,
      promoteur_id: $rootScope.commercialisateur.id,
      programme_id: transaction.programme_id,
      lot_id: transaction.lot_id
    });
    contactActiverEspaceClient.$save().then(response => {
      if (response.response.success === true) {
        $uibModalInstance.close();
        toaster.pop({
          type: "success",
          title: "",
          body: "La demande d'activation a bien été prise en compte. Le client va recevoir un e-mail l'invitant à activer son espace personnel"
        });
        $state.reload();
      } else {
        toaster.pop({
          type: "error",
          title: "",
          body: "Une erreur s’est produite lors de l'activation de l'espace client de " + $ctrl.contact.nom + " " + $ctrl.contact.prenom
        });
      }
    }).catch(error => {
      console.log(error);
      toaster.pop({
        type: "error",
        title: "",
        body: error.message
      });
    });
  };

  this.annuler = () => {
    $uibModalInstance.close();
  };
}]);
/**
 * Module definition and dependencies
 */

angular.module("Crm.EspaceClient", ["Crm.EspaceClient.Controller", "Crm.espace-client.templates"]).component("espaceClient", {
  controller: "EspaceClientCtrl",
  templateUrl: "src/espace-client/espace-client.html",
  bindings: {
    client: "<"
  }
}).component("espaceClientPanel", {
  controller: "EspaceClientCtrl",
  templateUrl: "src/espace-client/espace-client-panel.html",
  bindings: {
    client: "<",
    lot: "<",
    transaction: "<",
    programme: "<"
  }
});
angular.module('Crm.espace-client.templates', []).run(['$templateCache', function($templateCache) {$templateCache.put('src/espace-client/espace-client-panel.html','<div class="panel panel-default" ng-if="$root.use_userspace"><div class="panel-heading"><h3 class="panel-title">Espace client</h3></div><div class="panel-body text-center"> <button class="btn btn-tertiary btn-radius" ng-click="$ctrl.openModalEspaceClient()" ng-if="$ctrl.client.id && $ctrl.btnActiverEspaceClient && !$ctrl.client.statut_activation">Activer l\'espace client</button><div ng-if="$ctrl.client.statut_activation == \'DEMANDE_ACTIVATION\'"> <a class="label label-success" href="{{ $ctrl.espaceClientURL }}" target="_blank" ng-if="$ctrl.espaceClientURL">Espace client</a><p class="mb-0 mt-1" style="color : #555555"> Date de demande {{ $ctrl.client.date_demande_activation.date | moment: "DD/MM/YYYY" }}</p> <button class="btn btn-tertiary btn-radius mt-1" ng-click="$ctrl.openModalEspaceClient()"> <span>Renvoyer l\'email d\'activation</span></button></div><div ng-if="$ctrl.client.statut_activation === \'ACTIVATION\'"> <a class="label label-success" href="{{ $ctrl.espaceClientURL }}" target="_blank" ng-if="$ctrl.espaceClientURL" style="padding: 0.5rem;">Espace client est activ\xE9</a></div></div></div>');
$templateCache.put('src/espace-client/espace-client.html','<button class="btn btn-tertiary btn-radius btn-sm mt-1 w-100" ng-click="$ctrl.openModalEspaceClient()" ng-if="$ctrl.client.id && $ctrl.btnActiverEspaceClient && !$ctrl.client.statut_activation && $root.use_userspace">Activer l\'espace client</button><div class="mt-1" ng-if="$ctrl.client.statut_activation == \'DEMANDE_ACTIVATION\' && $root.use_userspace"> <a class="label label-success" href="{{ $ctrl.espaceClientURL }}" target="_blank" ng-if="$ctrl.espaceClientURL">Espace client</a><p class="text-center mb-0 mt-1" style="color : #555555"> Date de demande {{ $ctrl.client.date_demande_activation.date | moment: "DD/MM/YYYY" }}</p> <button class="btn btn-tertiary btn-sm mt-1 w-100" ng-click="$ctrl.openModalEspaceClient()"> <span>Renvoyer l\'email d\'activation</span></button></div><div class="mt-1" ng-if="$ctrl.client.statut_activation === \'ACTIVATION\' && $root.use_userspace"> <a class="btn btn-success btn-radius btn-sm w-100" href="{{ $ctrl.espaceClientURL }}" target="_blank" ng-if="$ctrl.espaceClientURL">Espace client est activ\xE9</a></div>');
$templateCache.put('src/espace-client/modal-confirmation-espace-client.html','<div class="modal-header"><h3 class="modal-title h4" id="modal-title">Confirmation</h3></div><div class="modal-body" id="modal-body"> Confirmez-vous l\'activation de l\'espace client pour {{ contact.nom + \' \' + contact.prenom }} ?</div><div class="modal-footer"> <button class="btn btn-danger" type="button" ng-click="$ctrl.annuler()">Annuler</button> <button class="btn btn-primary" type="button" ng-click="$ctrl.valider()">Confirmer</button></div>');
$templateCache.put('src/espace-client/modal-renvoyer-espace-client.html','<div class="modal-header"><h3 class="modal-title h4" id="modal-title">Confirmation</h3></div><div class="modal-body" id="modal-body"> Confirmez-vous l\'activation de l\'espace client pour {{ transaction.client_nom + \' \' + transaction.client_prenom }} ?</div><div class="modal-footer"> <button class="btn btn-danger" type="button" ng-click="$ctrl.annuler()">Annuler</button> <button class="btn btn-primary" type="button" ng-click="$ctrl.valider()">Confirmer</button></div>');}]);