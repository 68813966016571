angular.module("selectionsService.Service", ["ngResource"])
/**
 * Service
 */
.factory("SelectionsClient", ["$resource", "Config", function ($resource, Config) {
  var selectionApi = $resource(Config.API_BASE_URL + "/paniers?contact_id=:contactId", {
    contactId: "@contactId"
  }, {
    query: {
      isArray: false,
      transformResponse: function (data) {
        let result = angular.fromJson(data);
        result.data = result.data.map(r => new selectionApi(r));
        return result;
      }
    },
    update: {
      method: "PUT"
    }
  });
  return selectionApi;
}]);