angular.module("Crm.Toggle.Controller", []).controller("toggleCtrl", [function () {}]).controller("toggleWithHandleChangeCtrl", [function () {}]);
angular.module("Crm.Toggle", ["Crm.Toggle.Controller", "Crm.toggle.templates"]).component("toggle", {
  controller: "toggleCtrl",
  templateUrl: "src/toggle/toggle.html",
  bindings: {
    label: "<?",
    value: "=",
    addClass: "=?"
  }
}).component("toggleWithHandleChange", {
  controller: "toggleWithHandleChangeCtrl",
  templateUrl: "src/toggle/toggle-with-handle-change.html",
  bindings: {
    label: "<?",
    value: "<",
    addClass: "=?",
    handleChange: "&"
  }
});
;
angular.module('Crm.toggle.templates', []).run(['$templateCache', function($templateCache) {$templateCache.put('src/toggle/toggle-with-handle-change.html','<div class="content-toggle {{ $ctrl.addClass }}"><p>{{ $ctrl.label }}</p> <label class="toggle"><input type="checkbox" ng-model="$ctrl.value" ng-change="$ctrl.handleChange()"/><span class="slider round"></span></label></div>');
$templateCache.put('src/toggle/toggle.html','<div class="content-toggle {{ $ctrl.addClass }}"><p>{{ $ctrl.label }}</p> <label class="toggle" ng-click="$ctrl.toggle()"><input type="checkbox" ng-model="$ctrl.value"/><span class="slider round"></span></label></div>');}]);