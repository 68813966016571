/**
 * Module definition and dependencies
 */
angular.module('Bytes.Filter', [])
/**
 * Filter definitions
 */
.filter('bytes', function () {
  return function (bytes, precision) {
    if (isNaN(parseFloat(bytes)) || !isFinite(bytes) || bytes === 0) return '-';
    if (typeof precision === 'undefined') precision = 1;
    var units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'],
        number = Math.floor(Math.log(bytes) / Math.log(1024));
    return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) + ' ' + units[number];
  };
});
/**
 * Module definition and dependencies
 */

angular.module("Crm.Moment.Filter", [])
/**
 * Filter definitions
 */
.filter("moment", ["moment", "Config", function (moment, Config) {
  return function (date, format, relative) {
    if (!moment.isMoment(date)) {
      if (!date) {
        return "";
      }

      date = moment(date);

      if (!date.isValid()) {
        return "";
      }
    }

    if (relative) {
      let now = moment();

      if (now.isSame(date, "day")) {
        return "Today";
      }

      if (now.add(1, "day").isSame(date, "day")) {
        return "Tomorrow";
      }
    }

    return date.format(format || Config.DATE_STANDARD);
  };
}])
/**
 * Filter definitions
 */
.filter("fromNow", ["moment", function (moment) {
  return function (date) {
    if (!moment.isMoment(date)) {
      return "";
    }

    return date.fromNow();
  };
}]);