/**
 * Module definition and dependencies
 */
angular.module('configsService.Service', ['ngResource'])
/**
 * Service
 */
.factory('ConfigsCalendriers', ['$http', 'Config', function ($http, Config) {
  return {
    getConfigs: function (configParams) {
      return $http({
        cache: false,
        method: 'GET',
        url: Config.API_BASE_URL + '/configs/calendriers',
        params: configParams
      });
    },
    getConfigsAlertes: function (configParams) {
      return $http({
        cache: false,
        method: 'GET',
        url: Config.API_BASE_URL + '/configs/calendriers/alertes',
        params: configParams
      });
    }
  };
}]).factory('ConfigsCommercialisateurs', ['$http', 'Config', function ($http, Config) {
  return {
    getConfigCommercialisateurs: function () {
      return $http({
        cache: true,
        method: 'GET',
        url: Config.API_BASE_URL + '/configs/commercialisateurs'
      });
    },
    getFiscalites: function (configParams) {
      return $http({
        cache: true,
        method: 'GET',
        url: Config.API_BASE_URL + '/configs/commercialisateurs/fiscalites',
        params: configParams
      });
    }
  };
}]).factory('ConfigsAvancementProgrammes', ['$http', 'Config', function ($http, Config) {
  return {
    getAvancementsProgrammes: function () {
      return $http({
        cache: true,
        method: 'GET',
        url: Config.API_BASE_URL + '/configs/programme/avancements'
      });
    }
  };
}]).factory('ConfigsContacts', ['$http', 'Config', function ($http, Config) {
  return {
    getOrigines: function () {
      return $http({
        cache: true,
        method: 'GET',
        url: Config.API_BASE_URL + '/configs/contacts/origines'
      });
    },
    getActions: function () {
      return $http({
        cache: true,
        method: 'GET',
        url: Config.API_BASE_URL + '/configs/contacts/actions'
      });
    },
    getSources: function () {
      return $http({
        cache: true,
        method: 'GET',
        url: Config.API_BASE_URL + '/configs/contacts/sources'
      });
    },
    getAvancements: function () {
      return $http({
        cache: true,
        method: 'GET',
        url: Config.API_BASE_URL + '/configs/contacts/avancements'
      });
    },
    getGroupesAvancements: function () {
      return $http({
        cache: true,
        method: 'GET',
        url: Config.API_BASE_URL + '/configs/contacts/groupes-avancements'
      });
    },
    getToken: function (contact_id, configParams) {
      return $http({
        cache: true,
        method: 'GET',
        url: Config.API_BASE_URL + '/contacts/' + contact_id + '/token',
        params: configParams
      });
    }
  };
}]).factory('ConfigsLots', ['$http', 'Config', function ($http, Config) {
  return {
    getNatures: function (configParams) {
      return $http({
        cache: true,
        method: 'GET',
        url: Config.API_BASE_URL + '/configs/lots/natures',
        params: configParams
      });
    },
    getStatuts: function (configParams) {
      return $http({
        cache: true,
        method: 'GET',
        url: Config.API_BASE_URL + '/configs/lots/statuts',
        params: configParams
      });
    },
    getFiscalites: function (configParams) {
      return $http({
        cache: true,
        method: 'GET',
        url: Config.API_BASE_URL + '/configs/lots/fiscalites',
        params: configParams
      });
    },
    getTypes: function (configParams) {
      return $http({
        cache: true,
        method: 'GET',
        url: Config.API_BASE_URL + '/configs/lots/types',
        params: configParams
      });
    },
    getPrix: function (configParams) {
      return $http({
        cache: true,
        method: 'GET',
        url: Config.API_BASE_URL + '/configs/lots/prix',
        params: configParams
      });
    },
    getSurfaces: function (configParams) {
      return $http({
        cache: true,
        method: 'GET',
        url: Config.API_BASE_URL + '/configs/lots/surfaces',
        params: configParams
      });
    },
    getEtages: function (configParams) {
      return $http({
        cache: true,
        method: 'GET',
        url: Config.API_BASE_URL + '/configs/lots/etages',
        params: configParams
      });
    }
  };
}]).factory('ConfigsProgrammes', ['$http', 'Config', function ($http, Config) {
  return {
    getVilles: function () {
      return $http({
        cache: true,
        method: 'GET',
        url: Config.API_BASE_URL + '/configs/villes'
      });
    },
    getAvancementsProgrammes: function () {
      return $http({
        cache: true,
        method: 'GET',
        url: Config.API_BASE_URL + '/configs/programmes/avancements'
      });
    },
    getProgrammes: function (params) {
      const objParams = params ? Object.assign({
        limit: -1
      }, params) : {
        limit: -1
      };
      return $http({
        cache: false,
        method: 'GET',
        url: Config.API_BASE_URL + '/programmes/',
        params: objParams
      });
    }
  };
}]).factory('ConfigsTransactions', ['$http', 'Config', function ($http, Config) {
  return {
    getStatuts: function () {
      return $http({
        method: 'GET',
        url: Config.API_BASE_URL + '/configs/transactions/types'
      });
    }
  };
}]).factory('ConfigsDocuments', ['$http', 'Config', function ($http, Config) {
  return {
    getCategories: function (configParams) {
      return $http({
        method: 'GET',
        url: Config.API_BASE_URL + '/configs/documents/categories/',
        params: configParams
      });
    }
  };
}]).factory('ConfigsTransactionsEtats', ['$http', 'Config', function ($http, Config) {
  return {
    getEtats: function () {
      return $http({
        method: 'GET',
        url: Config.API_BASE_URL + '/configs/transactions/etats'
      });
    }
  };
}]).factory('ConfigsDenonciations', ['$http', 'Config', function ($http, Config) {
  return {
    getInfosDenonciations: function () {
      return $http({
        method: 'GET',
        url: Config.API_BASE_URL + '/configs/denonciations'
      });
    }
  };
}]).factory('ConfigsVendeurs', ['$http', 'Config', function ($http, Config) {
  return {
    getVendeurs: function () {
      return $http({
        cache: true,
        method: 'GET',
        url: Config.API_BASE_URL + '/configs/vendeurs'
      });
    }
  };
}]).factory('ConfigsPartenaires', ['$http', 'Config', function ($http, Config) {
  return {
    getPartenaires: function () {
      return $http({
        cache: true,
        method: 'GET',
        url: Config.API_BASE_URL + '/configs/partenaires'
      });
    }
  };
}]).factory('ConfigsContratReservation', ['$resource', 'Config', function ($resource, Config) {
  const api = $resource(Config.API_BASE_URL + '/configs/contrat-reservation', {}, {});
  return api;
}]).service('RechercheService', function () {
  let paramsRecherche;
  return {
    getParamsRecherche: function () {
      return paramsRecherche;
    },
    setParamsRecherche: function (value) {
      paramsRecherche = value;
    }
  };
}).service('ConfigService', function () {
  let params;
  return {
    getParams: function () {
      return params;
    },
    setParams: function (value) {
      params = value;
    }
  };
});