angular.module("Crm.SelectContact.Controller", ['contactsService.Service']).controller("selectContactCtrl", ['ContactFicheConsultees', 'Contacts', '$scope', function (ContactFicheConsultees, Contacts, $scope) {
  this.$onInit = () => {
    if (!this.labelPlaceholder) {
      this.labelPlaceholder = "Rechercher un contact";
    }
  };

  this.getContacts = search => {
    if (!search || search && search.length === 0) {
      return ContactFicheConsultees.getContactFicheConsultees({
        ignoreVendeursInterceptor: true
      }).then(response => {
        return this.formatContacts(response.data.data);
      }).catch(err => {
        console.log('promise error', err);
      });
    } else {
      return Contacts.query({
        ignoreVendeursInterceptor: true,
        q: search,
        limit: '-1'
      }).$promise.then(response => {
        return this.formatContacts(response.data);
      }).catch(err => {
        console.log('promise error', err);
      });
      ;
    }
  };

  this.getContact = contact => {
    if (!contact) {
      return;
    }

    this.contact = contact;
  }; // Ajoute à chaque contacts un label pour affichage uib-typeahead


  this.formatContacts = contacts => {
    const aContacts = [];

    if (!contacts) {
      return aContacts;
    }

    angular.forEach(contacts, function (contact, key) {
      contact.label = contact.nom + ' ' + contact.prenom;
      aContacts.push(contact);
    });
    return aContacts;
  };

  $scope.$watch(() => this.contact, (newVal, oldVal) => {
    if (oldVal && oldVal.id && !newVal.id) {
      this.selectContact = "";
      $scope.noResults = false;
    }
  });
}]);
angular.module("Crm.SelectContact", ["Crm.SelectContact.Controller", "Crm.select-contact.templates"]).component("selectContact", {
  controller: "selectContactCtrl",
  templateUrl: "src/select-contact/select-contact.html",
  bindings: {
    contact: "=",
    labelPlaceholder: "<?"
  }
});
angular.module('Crm.select-contact.templates', []).run(['$templateCache', function($templateCache) {$templateCache.put('src/select-contact/select-contact-typeahead.html',' <a><span>{{match.model.nom}} {{match.model.prenom}}</span><br/> <span><small>{{match.model.email}}</small></span></a>');
$templateCache.put('src/select-contact/select-contact.html','<div class="typeahead-container"> <input type="text" ng-model="$ctrl.selectContact" placeholder="{{$ctrl.labelPlaceholder}}" uib-typeahead="contact as contact.label for contact in $ctrl.getContacts($viewValue)" typeahead-loading="loading" typeahead-no-results="noResults" typeahead-min-length="0" typeahead-on-select="$ctrl.getContact($model)" typeahead-focus-on-select="$ctrl.getContacts()" typeahead-template-url="src/select-contact/select-contact-typeahead.html" class="form-control"><div class="loading-contacts" ng-show="loading"><div class="spinner-icon"></div></div><div class="no-results" ng-show="noResults"> <i class="material-icons">warning</i> Aucun r\xE9sultat</div></div>');}]);