/**
 * Module definition and dependencies
 */
angular.module('Crm.SearchInput.Controller', [])
/**
 * Controller
 */
.controller('SearchInputCtrl', ["$scope", "debounce", function ($scope, debounce) {
  /**
   * Init
   */
  this.$onInit = () => {
    this.loading = false;
    this.searchMinLength = this.searchMinLength ? this.searchMinLength : 3;
  };
  /**
   * getDataUibTypeahead
   */


  this.getDataUibTypeahead = search => {
    this.loading = true;
    return this.getData(search).then(data => {
      this.loading = false;
      return data;
    }).catch(error => {
      this.loading = false;
      console.log("error", error);
    });
  };
  /**
   * itemSelectedUibTypeahead
   */


  this.itemSelectedUibTypeahead = (item, model, label, event) => {
    this.search = item.label;
    this.itemSelected(item, model, label, event);
  };
  /**
   * watch search value
   */


  $scope.$watch(() => {
    return this.search;
  }, debounce(400, () => {
    if (!this.search) {
      this.itemSelected("");
      return;
    }
  }), true);
}]);
/**
 * Module definition and dependencies
 */

angular.module('Crm.SearchInput', ['Crm.SearchInput.Controller', 'Crm.search.templates'])
/**
 * Route component
 */
.component('searchInput', {
  controller: 'SearchInputCtrl',
  templateUrl: 'src/search/search-input/search-input.html',
  bindings: {
    search: '=',
    getData: '=',
    itemSelected: '='
  }
});
/**
 * Module definition and dependencies
 */

angular.module('Crm.SearchVille.Controller', ['geoApiGouv.Service'])
/**
 * Controller
 */
.controller('SearchVilleCtrl', ['Communes', '$scope', function (Communes, $scope) {
  /**
   * searchVilles
   */
  this.searchVilles = search => {
    return Communes.search({
      nom: search,
      fields: "nom,code,codesPostaux",
      format: 'geojson',
      geometry: 'centre'
    }).then(response => this.formatCities(response.data)).catch(error => {
      console.log("error", error);
    });
  };
  /**
   * formatCities
   */


  this.formatCities = data => {
    const cities = [];

    if (!data || !data.features) {
      return cities;
    }

    data.features.map(dataCity => {
      if (!dataCity.properties) {
        return;
      }

      dataCity.properties.codesPostaux.map(cp => {
        const city = {
          label: `${dataCity.properties.nom} (${cp})`,
          codePostal: cp
        };
        Object.assign(city, dataCity.properties);
        Object.assign(city, dataCity.geometry);
        delete city.codesPostaux;
        cities.push(city);
      });
    });
    return cities;
  };
  /**
   * setVilleSelected
   */


  this.setVilleSelected = item => {
    this.villeSelected = angular.copy(item);
  };
  /**
   * resetSearch
   */


  this.resetSearch = () => {
    this.search = '';
  };
  /**
   * Event reset-search-ville
   */


  $scope.$on('reset-search-ville', (event, item) => {
    this.resetSearch();
  });
}]);
/**
 * Module definition and dependencies
 */

angular.module('Crm.SearchVille', ['Crm.SearchVille.Controller', 'Crm.search.templates'])
/**
 * Route component
 */
.component('searchVille', {
  controller: 'SearchVilleCtrl',
  templateUrl: 'src/search/search-ville/search-ville.html',
  bindings: {
    search: '<?',
    villeSelected: '='
  }
});
angular.module('Crm.search.templates', []).run(['$templateCache', function($templateCache) {$templateCache.put('src/search/search-ville/search-ville.html','<search-input search="$ctrl.search" get-data="$ctrl.searchVilles" item-selected="$ctrl.setVilleSelected"/>');
$templateCache.put('src/search/search-input/search-input-typeahead.html','<div class="item-typeahead">{{match.model.label}}</div>');
$templateCache.put('src/search/search-input/search-input.html','<div class="typeahead-container search-input"> <input type="text" ng-model="$ctrl.search" uib-typeahead="item as item.label for items in $ctrl.getDataUibTypeahead($viewValue)" typeahead-loading="loading" typeahead-no-results="noResults" typeahead-min-length="$ctrl.searchMinLength" typeahead-on-select="$ctrl.itemSelectedUibTypeahead($item, $model, $label, $event)" typeahead-template-url="src/search/search-input/search-input-typeahead.html" class="form-control"><div class="loading" ng-show="loading"><div class="spinner-icon"></div></div><div class="no-results" ng-show="noResults"> <i class="material-icons">warning</i> Aucun r\xE9sultat</div></div>');}]);