/**
 * Module definition and dependencies
 */
angular.module('Crm.Lot.Controller', ['ngMessages', 'lotsService.Service'])
/**
 * Controller
 */
.controller('FormLotsCtrl', ['$scope', 'Programmes', 'ConfigsLots', function ($scope, Programmes, ConfigsLots) {
  var self = this;
  self.labelMultiSelect = {
    'itemsSelected': 'éléments sélectionnés',
    'selectAll': 'Tout sélectionner',
    'unselectAll': 'Tout déselectionner',
    'search': 'Chercher'
  };

  self.$onInit = function () {
    if (self.filtres === undefined) {
      self.filtres = {};
    }
    /* ---------- getProgrammes ---------- */


    Programmes.get({
      limit: -1
    }).$promise.then(function (response) {
      self.listProgrammes = response.data;
    }, function errorCallback(response) {
      console.log('Erreur factory Programmes get : ' + response);
      return false;
    });
    /* ---------- getStatuts ---------- */

    ConfigsLots.getStatuts(self.configsLots).then(function successCallback(response) {
      self.listStatuts = response.data;
    }, function errorCallback(response) {
      console.log('Erreur factory ConfigsLots getStatuts : ' + response);
      return false;
    });
    /* ---------- getTypes ---------- */

    ConfigsLots.getTypes(self.configsLots).then(function successCallback(response) {
      self.listTypes = response.data;
    }, function errorCallback(response) {
      console.log('Erreur factory ConfigsLots getTypes : ' + response);
      return false;
    });
  };

  $scope.$watch(() => {
    return self.filtres;
  }, (val, old) => {
    angular.extend(self.filtres, val);
  }, true);
}]).controller('ListLotsCtrl', ['$scope', 'NgTableParams', 'debounce', '$filter', 'Lots', function ($scope, NgTableParams, debounce, $filter, Lots) {
  var self = this;

  self.$onInit = function () {
    if (self.lots === undefined) {
      self.lots = [];
    }

    if (!self.totalSize) {
      self.totalSize = 0;
    }
  };

  self.listLots = new NgTableParams({}, {
    getData: function (params) {
      self.loading = true;
      var query = {
        'limit': params.count(),
        'page': params.page(),
        'order': params.sorting() ? params.sorting()[Object.keys(params.sorting())[0]] : '',
        'sort': params.sorting() ? Object.keys(params.sorting())[0] : ''
      };
      angular.extend(query, params.filter());
      return Lots.query(query).$promise.then(function (response) {
        self.loading = false;
        self.totalResult = response.total;
        return response.data;
      }).catch(function (err) {
        console.log('promise error', err);
      });
    }
  });

  self.getDocuments = function (item) {
    if (!item && !item.programmeId && !item.id) {
      return;
    }

    if (item.itemShowDocuments) {
      self.deleteItemShowDocuments();
      self.deleteShowDocuments();
      return;
    }

    self.deleteItemShowDocuments();
    self.deleteShowDocuments(); // Get index de l'id click

    const indexLot = self.listLots.data.map(l => l.id).indexOf(item.id);

    if (indexLot == -1) {
      return;
    }

    self.listLots.data[indexLot].itemShowDocuments = true; // Add row showDocuments

    self.listLots.data.splice(indexLot + 1, 0, {
      showDocuments: true,
      programme_id: item.programme_id,
      lot_id: item.id
    });
  };

  self.deleteItemShowDocuments = function () {
    const itemShowDocuments = self.listLots.data.find(l => l.itemShowDocuments === true);

    if (itemShowDocuments) {
      const indexItemShowDocuments = self.listLots.data.indexOf(itemShowDocuments);

      if (indexItemShowDocuments == -1) {
        return;
      }

      self.listLots.data[indexItemShowDocuments].itemShowDocuments = false;
    }
  };

  self.deleteShowDocuments = function () {
    const showDocuments = self.listLots.data.find(l => l.showDocuments === true);

    if (showDocuments) {
      const indexShowDocuments = self.listLots.data.indexOf(showDocuments);

      if (indexShowDocuments == -1) {
        return;
      }

      self.listLots.data.splice(indexShowDocuments, 1);
    }
  };

  $scope.$watch(() => this.filtres, debounce(300, function () {
    angular.extend(self.listLots.filter(), self.filtres);
  }), true);
}]);
/**
 * Module definition and dependencies
 */

angular.module('Crm.Lot', ['Crm.Lot.Controller', 'Crm.lot.templates']).component('formLots', {
  controller: 'FormLotsCtrl',
  templateUrl: 'src/lot/form-lots.html',
  bindings: {
    filtres: '='
  }
}).component('listLots', {
  controller: 'ListLotsCtrl',
  templateUrl: 'src/lot/list-lots.html',
  bindings: {
    filtres: '<',
    documents: '=',
    totalSize: '='
  }
});
/**
 * Module definition and dependencies
 */

angular.module("lotsService.Service", ["ngResource"])
/**
 * Service
 */
.factory("Lots", ["$resource", "Config", function ($resource, Config) {
  var lotsApi = $resource(Config.API_BASE_URL + "/lots/", {
    membreId: "@membreId"
  }, {
    query: {
      isArray: false
    }
  });
  return lotsApi;
}]).factory("Lot", ["$resource", "Config", function ($resource, Config) {
  var lotsApi = $resource(Config.API_BASE_URL + "/lots/:lotId", {
    lotId: "@lotId"
  }, {
    query: {
      isArray: false
    }
  });
  return lotsApi;
}]).factory("LotDetails", ["$resource", "Config", function ($resource, Config) {
  var lotsApi = $resource(Config.API_BASE_URL + "/lots/:lotId/details", {
    lotId: "@lotId"
  }, {
    query: {
      isArray: false
    }
  });
  return lotsApi;
}]).factory("LotPrix", ["$resource", "Config", function ($resource, Config) {
  var lotsApi = $resource(Config.API_BASE_URL + "/lots/:lotId/prix", {
    lotId: "@lotId"
  }, {
    get: {
      isArray: true
    }
  });
  return lotsApi;
}]).service("LotService", function () {
  var tmp;
  return {
    getLotService: function () {
      return tmp;
    },
    setLotService: function (value) {
      tmp = value;
    }
  };
});
angular.module('Crm.lot.templates', []).run(['$templateCache', function($templateCache) {$templateCache.put('src/lot/form-lots.html','<div class="row mb-1"><div class="col-xs-12 col-md-6"><div class="form-group"><div class="form-group--select" ng-if="$ctrl.listProgrammes"> <i class="material-icons">arrow_drop_down</i><multiselect id="programmes" name="programmes" classes-btn="\'form-control\'" model-string-to-object-array ng-model="$ctrl.filtres.programme_id" options="$ctrl.listProgrammes" placeholder="Programme" labels="$ctrl.labelMultiSelect" id-prop="id" display-prop="nom" show-search="false" show-select-all="false" show-unselect-all="false"></multiselect></div></div></div><div class="col-xs-12 col-md-6"><div class="form-group"><div class="form-group--select" ng-if="$ctrl.listTypes"> <i class="material-icons">arrow_drop_down</i><multiselect name="types" classes-btn="\'form-control\'" model-string-to-object-array ng-model="$ctrl.filtres.type" options="$ctrl.listTypes" placeholder="Type" labels="$ctrl.labelMultiSelect" id-prop="id" display-prop="label" show-search="false" show-select-all="false" show-unselect-all="false"></multiselect></div></div></div><div class="col-xs-12 col-md-6"><div class="form-group"><div class="form-group--select" ng-if="$ctrl.listStatuts"> <i class="material-icons">arrow_drop_down</i><multiselect name="status" classes-btn="\'form-control\'" model-string-to-object-array ng-model="$ctrl.filtres.statut_id" options="$ctrl.listStatuts" placeholder="Statut" labels="$ctrl.labelMultiSelect" id-prop="id" display-prop="label" show-search="false" show-select-all="false" show-unselect-all="false"></multiselect></div></div></div></div>');
$templateCache.put('src/lot/list-lots.html','<div class="clearfix" ng-class="{\'hiddenPager\' : $ctrl.listLots._settings.total <= 10}" ng-show="!$ctrl.loading"><div class="content-results"><table ng-table="$ctrl.listLots" class="table table-striped table-bordered table-hover table-vmiddle"><tr ng-repeat="item in $data" ng-if="$data.length > 0"><td ng-if="!item.showDocuments" ng-click="$ctrl.getDocuments(item)"> <span><i class="material-icons" ng-class="{\'rotate-90\': item.itemShowDocuments}">chevron_right</i></span> {{ item.showDocuments }}</td><td ng-if="!item.showDocuments" ng-click="$ctrl.getDocuments(item)" data-title="\'Programme\'"> {{ item.programme_nom }}</td><td ng-if="!item.showDocuments" class="word-break-k-all" ng-click="$ctrl.getDocuments(item)" data-title="\'Nom\'" ng-attr-title="{{ item.nom }}"> {{ item.nom }}</td><td ng-if="!item.showDocuments" class="word-break-k-all" ng-click="$ctrl.getDocuments(item)" data-title="\'Type\'" ng-attr-title="{{ item.type }}"> {{ item.type }}</td><td ng-if="!item.showDocuments" class="word-break-k-all" ng-click="$ctrl.getDocuments(item)" data-title="\'Statut\'" ng-attr-title="{{ item.statut_label }}"> {{ item.statut_label }}</td><td ng-if="item.showDocuments" colspan="5" style="background-color: #f9f9f9; border-bottom: 2px solid #e7e7e7e7;"><list-documents-programme-lot programme-id="item.programme_id" lot-id="item.lot_id" documents="$ctrl.documents" total-size="$ctrl.totalSize"></list-documents-programme-lot></td></tr><tr ng-if="$data.length === 0"><td colspan="10"><p> Aucun lot</p></td></tr></table></div></div><div class="scroll-footer" style="text-align:center; padding-left:8px;" ng-show="$ctrl.loading" ng-if="!$ctrl.btnShow || $ctrl.display"> <span>En chargement&nbsp;&nbsp;&nbsp;<img ng-src="{{$root.pathImageAjaxLoader}}"/></span></div>');}]);