angular.module("Crm.Country.Controller", ['countryService.Service']).controller("CountryCtrl", ['Country', function (Country) {
  this.$onInit = () => {
    const defaultCountryId = 63; // France

    const validLabel = ['nom', 'tel', 'nom-tel'];
    this.countries = [];
    this.countryId = this.countryId ? parseInt(this.countryId) : defaultCountryId;
    this.label = this.label && validLabel.includes(this.label) ? this.label : 'nom';
    this.getCountries();
  };

  this.getCountries = () => {
    Country.query(this.params).$promise.then(response => {
      this.countries = response;
      this.findCountry();
    }).catch(error => {
      console.log('error', error);
    });
  };

  this.findCountry = () => {
    if (this.country) {
      return;
    }

    this.country = this.countries.find(c => c.id === this.countryId);
  };
}]);
angular.module('Crm.Country', ['Crm.Country.Controller', 'Crm.country.templates']).component('country', {
  controller: 'CountryCtrl',
  templateUrl: 'src/country/country.html',
  bindings: {
    countryId: '<',
    country: '=',
    label: '@?',
    params: '<?'
  }
});
angular.module('Crm.country.templates', []).run(['$templateCache', function($templateCache) {$templateCache.put('src/country/country.html','<select class="form-control" ng-model="$ctrl.country" ng-options="country as country.nom for country in $ctrl.countries" track-by="country.id" ng-if="$ctrl.label === \'nom\'"></select> <select class="form-control" ng-model="$ctrl.country" ng-options="country as (\'\\+\' + country.tel) for country in $ctrl.countries" track-by="country.id" ng-if="$ctrl.label === \'tel\'"></select> <select class="form-control" ng-model="$ctrl.country" ng-options="country as (country.nom + \' \\+\' + country.tel) for country in $ctrl.countries" track-by="country.id" ng-if="$ctrl.label === \'nom-tel\'"></select>');}]);