angular.module("Crm.AsDateDirective", []).directive("asDate", function () {
  return {
    require: "ngModel",
    link: function (scope, element, attrs, modelCtrl) {
      modelCtrl.$parsers.unshift(function (viewValue) {
        if (viewValue instanceof Date) {
          return viewValue; //viewValue.setMinutes(viewValue.getMinutes() - viewValue.getTimezoneOffset());
        } else if (modelCtrl.$modelValue) {
          //Pour la récupération de l'heure
          var lastDate = moment(modelCtrl.$modelValue); //Récupération date avec init de l'heure

          var splitDate = viewValue.split("/");
          var d = new Date(splitDate[2], parseInt(splitDate[1]) - 1, splitDate[0], lastDate.hour(), lastDate.minute(), 0);
          viewValue = d;
        } else {
          var lastDate = modelCtrl.$viewValue.split("/");
          var d = new Date(lastDate[2], parseInt(lastDate[1]) - 1, lastDate[0]);
          viewValue = d;
        }

        return viewValue;
      });
      modelCtrl.$formatters.push(function (modelValue) {
        if (!modelValue) {
          return undefined;
        }

        let date = moment(modelValue);
        let dt = new Date(date.year(), date.month(), date.date(), date.hour(), date.minute(), 0);
        return dt;
      });
    }
  };
}).directive('dUibDatepickerPopup', ["dateFilter", "uibDateParser", "uibDatepickerPopupConfig", function (dateFilter, uibDateParser, uibDatepickerPopupConfig) {
  return {
    restrict: 'A',
    priority: 1,
    require: 'ngModel',
    link: function (scope, element, attr, ngModel) {
      var dateFormat = attr.uibDatepickerPopup || uibDatepickerPopupConfig.datepickerPopup;

      ngModel.$validators.date = function (modelValue, viewValue) {
        var value = viewValue || modelValue;

        if (!attr.ngRequired && !value) {
          return true;
        }

        if (angular.isNumber(value)) {
          value = new Date(value);
        }

        if (!value) {
          return true;
        } else if (angular.isDate(value) && !isNaN(value)) {
          return true;
        } else if (angular.isString(value)) {
          var date = uibDateParser.parse(value, dateFormat);
          return !isNaN(date);
        } else {
          return false;
        }
      };

      ngModel.$formatters.push(function (modelValue) {
        if (!modelValue) {
          return undefined;
        }

        return new Date(modelValue);
      });
    }
  };
}]);
/**
 * Module definition and dependencies
 */

angular.module("Crm.LibPhoneNumberDirective", []).directive("isValidPhoneNumber", function () {
  return {
    restrict: "A",
    require: "ngModel",
    link: function (scope, element, attrs, ctrl) {
      function isValidPhoneNumber(value) {
        if (!value) {
          return undefined;
        }

        let phoneNumberIsValid;
        const phoneNumber = new libphonenumber.parsePhoneNumberFromString(value, "FR");

        if (Object.keys(phoneNumber).length > 0) {
          phoneNumberIsValid = phoneNumber.isValid();
        }

        return phoneNumberIsValid ? phoneNumber.formatNational() : undefined;
      }

      ctrl.$formatters.push(isValidPhoneNumber);
      ctrl.$parsers.push(isValidPhoneNumber);
    }
  };
}).directive("isValidMobileNumber", function () {
  return {
    restrict: "A",
    require: "ngModel",
    link: function (scope, element, attrs, ctrl) {
      function isValidPhoneNumber(value) {
        if (!value) {
          return undefined;
        }

        let phoneNumberIsValid;
        const phoneNumber = new libphonenumber.parsePhoneNumberFromString(value, "FR");

        if (Object.keys(phoneNumber).length > 0) {
          phoneNumberIsValid = phoneNumber.isValid() && phoneNumber.getType() === "MOBILE";
        }

        return phoneNumberIsValid ? phoneNumber.formatNational() : undefined;
      }

      ctrl.$formatters.push(isValidPhoneNumber);
      ctrl.$parsers.push(isValidPhoneNumber);
    }
  };
}).directive("telephoneFrValidator", function () {
  return {
    require: "ngModel",
    scope: {
      country: "@"
    },
    link: function (scope, elm, attrs, ngModel) {
      scope.$watch(function () {
        if (!attrs || !attrs.country) {
          return;
        }

        const country = JSON.parse(attrs.country);
        isValidPhoneFr(country.id);
      }, function (newValue) {});
      scope.$watch("country", function (countryValue) {
        const country = JSON.parse(countryValue);
        return isValidPhoneFr(country);
      });

      function isValidPhoneFr(country) {
        const franceId = 63;
        const phoneLengthFr = 10;
        const validPhoneStartWith = ["06", "07"];

        if (!ngModel || !ngModel.$modelValue || !country || country !== franceId) {
          return;
        }

        const phoneStart = ngModel.$modelValue.slice(0, 2);

        if (ngModel.$modelValue.length === phoneLengthFr && phoneStart && validPhoneStartWith.includes(phoneStart)) {
          ngModel.$setValidity('length', true);
          return;
        }

        ngModel.$setValidity('length', false);
      }
    }
  };
});
/**
 * Module definition and dependencies
 */

angular.module('Crm.ModelStringToArray', []).directive('modelStringToArray', function () {
  return {
    require: 'ngModel',
    link: function (scope, element, attrs, modelCtrl) {
      modelCtrl.$parsers.unshift(function (viewValue) {
        if (viewValue) {
          return viewValue.join(',');
        }

        return '';
      });
      modelCtrl.$formatters.push(function (modelValue) {
        if (modelValue) {
          return modelValue.split(',');
        }

        return [];
      });
    }
  };
}).directive('modelStringToObjectArray', function () {
  return {
    restrict: 'A',
    require: ['ngModel', 'multiselect'],
    link: function (scope, element, attrs, Ctrls) {
      var modelCtrl = Ctrls[0];
      modelCtrl.$parsers.unshift(function (viewValue) {
        var idProp = attrs.idProp;
        return viewValue.map(i => {
          return i[idProp];
        }).join(',');
      });
      modelCtrl.$formatters.push(function (modelValue) {
        if (!modelValue || !attrs.options || !attrs.idProp) {
          return false;
        }

        var result = [];
        var options = scope.$eval(attrs.options);
        var idProp = attrs.idProp;
        var idPropIsInteger = attrs.idPropIsInteger;
        var optionsArrayId = options.map(item => {
          if (typeof item[idProp] === 'number') {
            return item[idProp];
          }

          return item[idProp].toLowerCase();
        });

        if (idPropIsInteger !== 'false') {
          var idsArray = modelValue.toString().split(',').map(Number);
        } else {
          var idsArray = modelValue.split(',').map(i => {
            return i.toLowerCase();
          });
        }

        idsArray.forEach(function (i) {
          var index = optionsArrayId.indexOf(i);

          if (index !== -1) {
            if (typeof options[index] === 'object') {
              result.push(options[index]);
            } else {
              result.push({
                idProp: options[index]
              });
            }
          }
        });
        console.log("result", result);
        return result;
      });
    }
  };
});
/**
 * Module definition and dependencies
 */

angular.module("Crm.TimerDirective", []).directive("timerArka", ["$timeout", function ($timeout) {
  return {
    restrict: "A",
    scope: true,
    link: function (scope, el, attr) {
      function compute() {
        var str = "";
        var days;
        var interval;
        var timer = attr.timerArka;
        str += "<div class='topbar'>";

        if (timer) {
          if (attr.timerArkaLabel) {
            str += "<p>" + attr.timerArkaLabel + "</p>";
          } else {
            str += "<p >Option echue dans</p>";
          }

          str += "<div class='timerArka'>";
          var date = moment(timer);
          var now = moment();

          if (now < date) {
            interval = moment.duration(date.diff(now));
            days = date.diff(now, "days");

            if (days == 0) {
              scope.danger = true;
            }

            str += "<span>" + days + "" + "  j  " + "</span>" + "<span>" + interval.get("hours") + "" + " h " + "</span>" + "<span>" + interval.get("minutes") + "" + " mn " + "</span>" + "<span>" + interval.get("seconds") + "" + " s " + "</span>";
          } else {
            str = '<p class="m-0">Option échue</p>';
          }

          str += "</div>";
        }

        str += "</div>";
        el.html(str);
        $timeout(compute, 1000);
      }

      compute();
    }
  };
}]).directive("timerPurge", ["$timeout", function ($timeout) {
  return {
    restrict: "A",
    scope: true,
    link: function (scope, el, attr) {
      function compute() {
        var str = "";
        var days;
        var interval;
        var timer = attr.timerPurge;
        str += "<div class='topbar'>";

        if (timer) {
          if (attr.timerPurgeLabel) {
            str += "<p>" + attr.timerPurgeLabel + "</p>";
          } else {
            str += "<p>En attente purge SRU</p>";
          }

          str += "<div class='timerArka'>";
          var date = moment(timer);
          var now = moment();

          if (now < date) {
            interval = moment.duration(date.diff(now));
            days = date.diff(now, "days");

            if (days == 0) {
              scope.danger = true;
            }

            str += "<span>" + days + "" + "  j  " + "</span>" + "<span>" + interval.get("hours") + "" + " h " + "</span>" + "<span>" + interval.get("minutes") + "" + " mn " + "</span>" + "<span>" + interval.get("seconds") + "" + " s " + "</span>";
          } else {
            str = '<p class="m-0">Purge SRU échue</p>';
          }

          str += "</div>";
        }

        str += "</div>";
        el.html(str);
        $timeout(compute, 1000);
      }

      compute();
    }
  };
}]).directive("time", ["$timeout", function ($timeout) {
  return {
    restrict: "A",
    scope: true,
    link: function (scope, el, attr) {
      function time() {
        var timer = moment().locale("fr");
        var timerHtml = '<span class="time"> Le <span class="date">' + timer.format("DD MMMM YYYY") + '<span> <br> <span class="hours">' + timer.format("HH:mm") + "<span> </span>";
        el.html(timerHtml);
        $timeout(time, 1000);
      }

      time();
    }
  };
}]);