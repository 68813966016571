angular.module("denoncesService.Service", ["ngResource"])
/**
 * Service
 */
.factory("DenoncesClient", ["$resource", "Config", function ($resource, Config) {
  var denonciationApi = $resource(Config.API_BASE_URL + "/denonciations?contact_id=:contactId", {
    contactId: "@contactId"
  }, {
    query: {
      isArray: false,
      transformResponse: function (data) {
        let result = angular.fromJson(data);
        result.data = result.data.map(r => new denonciationApi(r));
        return result;
      }
    },
    update: {
      method: "PUT"
    }
  });
  return denonciationApi;
}]);