angular.module("Crm.Files.Controller", []).controller("FilesCtrl", [function () {}]).controller("DownloadFileCtrl", [function () {
  this.download = () => {
    if (this.fileDisabled) {
      return;
    }

    const maWindow = window.open();
    maWindow.location.href = this.fileUrl;
  };
}]);
angular.module("Crm.Files", ["Crm.Files.Controller", "Crm.files.templates"]).component("files", {
  controller: "FilesCtrl",
  templateUrl: "src/files/files.html",
  bindings: {
    filesTitle: '@?',
    addClassTitle: '@?',
    files: '<'
  }
}).component("downloadFile", {
  controller: "DownloadFileCtrl",
  templateUrl: "src/files/download-file.html",
  bindings: {
    fileUrl: '<',
    fileTitle: '<',
    fileDisabled: '<',
    fileIconClass: '<'
  }
});
angular.module('Crm.files.templates', []).run(['$templateCache', function($templateCache) {$templateCache.put('src/files/download-file.html','{{$ctrl.fileTitle}}<div ng-click="$ctrl.download()" tooltip-placement="left" uib-tooltip="T\xE9l\xE9charger"><span class="file-icon {{$ctrl.fileIconClass}}" ng-class="{disabled: $ctrl.fileDisabled}"></span></div>');
$templateCache.put('src/files/files.html','<div ng-if="$ctrl.files.length > 0"><h4 ng-if="$ctrl.filesTitle" class="{{$ctrl.addClassTitle}}">{{$ctrl.filesTitle}}</h4><download-file ng-repeat="file in $ctrl.files" file-url="file.url" file-title="file.title" file-disabled="file.disabled" file-icon-class="file.iconClass"></download-file></div>');}]);