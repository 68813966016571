/**
 * Module definition and dependencies
 */
angular.module("Crm.Vendeur.Controller", ["ngMessages"])
/**
 * Controller
 */
.controller("VendeurCtrl", [function () {
  this.$onInit = function () {
    this.vendeurNomPrenom = this.vendeur.nom + " " + this.vendeur.prenom;
  };
}]);
/**
 * Module definition and dependencies
 */

angular.module("Crm.Vendeur", ["Crm.Vendeur.Controller", "Crm.vendeur.templates"])
/**
 * component
 */
.component("vendeur", {
  controller: "VendeurCtrl",
  templateUrl: "src/vendeur/vendeur.html",
  bindings: {
    vendeur: "<"
  }
});
angular.module('Crm.vendeur.templates', []).run(['$templateCache', function($templateCache) {$templateCache.put('src/vendeur/vendeur.html','<div class="panel panel-default vendeur"><div class="panel-heading"><h3 class="panel-title">Vendeur</h3></div><div class="panel-body"><div class="row mt-1"><div class="col-xs-12"><div class="form-group"> <label>Soci\xE9t\xE9</label><div class="form-group--select"> <input type="text" class="form-control" ng-model="$ctrl.vendeur.employeur_nom" disabled/></div></div></div><div class="col-xs-12"><div class="form-group"> <label>Contact</label><div class="form-group--select"> <input type="text" class="form-control" ng-model="$ctrl.vendeurNomPrenom" disabled/></div></div></div><div class="col-xs-12"><div class="form-group"> <label>T\xE9l\xE9phone contact</label><div class="form-group--select"> <input type="text" class="form-control" ng-model="$ctrl.vendeur.tel" disabled/></div></div></div><div class="col-xs-12"><div class="form-group"> <label>T\xE9l\xE9phone mobile contact</label><div class="form-group--select"> <input type="text" class="form-control" ng-model="$ctrl.vendeur.tel_mobile" disabled/></div></div></div><div class="col-xs-12"><div class="form-group"> <label>Email contact</label><div class="form-group--select"> <input type="text" class="form-control" ng-model="$ctrl.vendeur.email" disabled/></div></div></div></div></div></div>');}]);