/**
 * Module definition and dependencies
 */
angular.module('commercialisateursService.Service', ['ngResource'])
/**
 * Service
 */
.factory('Commercialisateur', ['$resource', 'Config', function ($resource, Config) {
  var commercialisateurApi = $resource(Config.API_BASE_URL + '/commercialisateurs/:commercialisateurId', {
    commercialisateurId: '@commercialisateurId'
  }, {
    query: {
      isArray: false
    }
  });
  return commercialisateurApi;
}]).service('CommercialisateurService', function () {
  let commercialisateur;
  return {
    getCommercialisateur: function () {
      return commercialisateur;
    },
    setCommercialisateur: function (value) {
      return commercialisateur = value;
    }
  };
});
/**
 * Module definition and dependencies
 */

angular.module('countryService.Service', ['ngResource'])
/**
 * Service
 */
.factory('Country', ['$resource', 'Config', function ($resource, Config) {
  const api = $resource(Config.API_BASE_URL + '/country', {}, {
    query: {
      isArray: true
    }
  });
  return api;
}]);
/**
 * Module definition and dependencies
 */

angular.module('exportsService.Service', ['ngResource']).factory('ExportsTransactions', ['$resource', 'Config', function ($resource, Config) {
  const api = $resource(`${Config.API_BASE_URL}/transactions/optionssignatureexcel`, {}, {});
  return api;
}]);
/**
 * Module definition and dependencies
 */

angular.module('geoApiGouv.Service', ['ngResource']).factory('Communes', ['$http', 'Config', function ($http, Config) {
  return {
    'search': params => {
      return $http({
        method: 'GET',
        url: Config.API_BASE_GEO_API_GOUV + '/communes',
        params
      });
    }
  };
}]);
/**
 * Module definition and dependencies
 */

angular.module('messageService.Service', ['ngResource'])
/**
 * Service
 */
.factory('Message', ['$resource', 'Config', function ($resource, Config) {
  var api = $resource(Config.API_BASE_URL + '/messages/', {}, {
    update: {
      method: 'PUT'
    },
    query: {
      isArray: false
    }
  });
  return api;
}]);
/**
 * Module definition and dependencies
 */

angular.module("Crm.Moment.Service", [])
/**
 * Service
 */
.factory("moment", ["$window", function ($window) {
  //Get moment object from window
  let moment = $window.moment || null;

  if (!moment) {
    throw new Error("Missing moment library. Did you load it in your page?");
  } //Get prototype


  let proto = Object.getPrototypeOf(moment());
  /**
   * Get minutes since midnight
   */

  proto.getTime = function () {
    return this.diff(this.clone().startOf("day"), "minutes");
  };
  /**
   * Set time as minutes since midnight
   */


  proto.setTime = function (time) {
    return this.startOf("day").add(time, "minutes");
  };
  /**
   * Set base time
   */


  moment.setBaseTime = function (baseTime) {
    let offset = new Date(baseTime).getTime() - Date.now();

    moment.now = function () {
      return Date.now() + offset;
    };
  }; //Return moment


  return moment;
}]);