angular.module("toasterService.Service", ["ngResource", "toaster"]).factory("ToasterService", ["toaster", "$rootScope", function (toaster, $rootScope) {
  $rootScope.$on("toasterError", (event, error) => {
    toasterPop("error", "", error);
  });
  /**
   * Toaster transactions
   */

  $rootScope.$on("transactionAbandonnee", () => {
    toasterPop("success", "", "La transaction a bien été abandonnée.");
  });
  $rootScope.$on("transactionValider", () => {
    toasterPop("success", "", "La transaction a bien été mise à jour.");
  });
  $rootScope.$on("transactionUpdate", () => {
    toasterPop("success", "", "La transaction a bien été validée.");
  });
  $rootScope.$on("transactionNouvelleDemandeSignature", () => {
    toasterPop("success", "", "Une nouvelle demande de signature a bien été effectuée.");
  }); // --------------------------- Fin transactions ---------------------------

  /**
   * Toaster contrat
   */

  $rootScope.$on("contratSupprime", () => {
    toasterPop("success", "", "Le contrat a bien été supprimé.");
  });
  $rootScope.$on("contratGenerer", () => {
    toasterPop("success", "", "Le contrat a bien été généré.");
  });
  $rootScope.$on("notification_contrat_generer", (event, data) => {
    if (!data || !data.messageSujet) {
      return;
    }

    toasterPop("success", "", data.messageSujet);
  });
  $rootScope.$on("contratRegenerer", () => {
    toasterPop("success", "", "Le contrat a bien été regénéré.");
  }); // --------------------------- Fin contrat ---------------------------

  /**
   * Toaster signature
   */

  $rootScope.$on("signatureDemandeEffectuee", () => {
    toasterPop("success", "", "La demande signature a bien été effectuée.");
  });
  $rootScope.$on("signatureAbandonne", () => {
    toasterPop("success", "", "La signature a bien été abandonnée.");
  }); // --------------------------- Fin signature ---------------------------

  /**
   * Toaster recommande
   */

  $rootScope.$on("recommandeEnvoye", () => {
    toasterPop("success", "", "Le recommandé a bien été envoyé.");
  }); // --------------------------- Fin recommande ---------------------------

  const toasterPop = (type, title, body) => {
    toaster.pop({
      type,
      title,
      body
    });
  };

  return {};
}]);